
// import React from 'react';
// import { Card, CardMedia, CardContent, Typography, Button, Box } from '@mui/material';
// import { Link } from 'react-router-dom';

// const normalizeTitle = (title) => {
//   return title
//     .toLowerCase()
//     .replace(/:/g, '') // Remove colons
//     .replace(/\s+/g, '-') // Replace spaces with hyphens
//     .trim(); // Remove leading and trailing spaces
// };

// const PostCard = ({ post }) => {
//   const encodedTitle = normalizeTitle(post.title);

//   return (
//     <Box sx={{ p: 0, mb: 0 }}> {/* Adjusted container */}
//       <Card
//         sx={{
//           width: '100%',
//           maxWidth: '380px',
//           mb: 0, 
//           mx: 'auto', 
//           boxShadow: 'none',
//         }}
//       >
//         <CardMedia
//           component="img"
//           height="250"
//           image={post.image}
//           alt={post.title}
//           sx={{
//             objectFit: 'cover',
//           }}
//         />
//         <CardContent
//           sx={{
//             padding: 1, 
//           }}
//         >
//           <Typography
//             variant="h6"
//             component="h3"
//             sx={{
//               fontSize: '14px',
//               fontWeight: 'bold',
//               mb: 1,
//             }}
//           >
//             {post.title}
//           </Typography>
//           <Button
//             variant="text"
//             color="primary"
//             component={Link}
//             to={`/post/${encodedTitle}`}
//             aria-label={`Read more about ${post.title}`}
//             sx={{
//               textTransform: 'none',
//             }}
//           >
//             Read More »
//           </Button>
//         </CardContent>
//       </Card>
//     </Box>
//   );
// };

// export default PostCard;

import React from 'react';
import { Card, CardMedia, CardContent, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const normalizeTitle = (title) => {
  return title
    .toLowerCase()
    .replace(/:/g, '')
    .replace(/\s+/g, '-')
    .trim(); 
};

const PostCard = ({ post }) => {
  const encodedTitle = normalizeTitle(post.title);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
      {/* Top Card - Image */}
      <Card
        sx={{
          width: '100%',
          maxWidth: '380px',
          mb: 0,
          boxShadow: 'none',
          borderRadius: '16px 16px 0 0',
        }}
      >
        <CardMedia
          component="img"
          height="250"
          image={post.image}
          alt={post.title}
          sx={{
            objectFit: 'cover',
            cursor: 'pointer',
          }}
          onClick={() => window.location.href = `/post/${encodedTitle}`}
        />
      </Card>

      <Card
        sx={{
          width: '100%',
          maxWidth: '380px',
          boxShadow: 3, 
          textAlign: 'center',
          cursor: 'pointer',
          mt: -1, 
          borderRadius: '0 0 16px 16px',
        }}
        onClick={() => window.location.href = `/post/${encodedTitle}`}
      >
        <CardContent
          sx={{
            padding: 2,
          }}
        >
          <Typography
            variant="h6"
            component="h3"
            sx={{
              fontSize: '14px',
              fontWeight: 'bold',
              mb: 1,
            }}
          >
            {post.title}
          </Typography>
          <Button
            variant="text"
            color="primary"
            component="span"
            sx={{
              textTransform: 'none',
            }}
          >
            Read More »
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PostCard;
