
import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import blogContent from './blogContent';
import { Container, Typography, CardMedia, Divider, Avatar, Stack } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const normalizeTitle = (title) => {
  return title
    .toLowerCase()
    .replace(/:/g, '')
    .replace(/\s+/g, ' ')
    .trim();
};

const BlogDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { title } = useParams();
  const decodedTitle = decodeURIComponent(title).replace(/-/g, ' ');
  const normalizedDecodedTitle = normalizeTitle(decodedTitle);

  console.log(`Decoded Title: ${normalizedDecodedTitle}`);
  console.log(`Available Titles: ${blogContent.map(post => normalizeTitle(post.title))}`);

  const post = blogContent.find(post => normalizeTitle(post.title) === normalizedDecodedTitle);

  if (!post) {
    return <Typography variant="h6">Post not found</Typography>;
  }

  // Get today's date
  const todayDate = new Date().toLocaleDateString();

  return (
    <Container sx={{ mt: 10 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2, fontSize: 12 }}>
        <Link color="inherit" href="/blogs">
          Blogs
        </Link>
        <Typography color="textPrimary" sx={{ fontSize: 12 }}>
          {post.title}
        </Typography>
      </Breadcrumbs>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 2 }}>
        {post.title}
      </Typography>
      <Divider sx={{ mb: 2 }} />

      {/* Author section */}
      <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Avatar
          sx={{ width: 40, height: 40 }} 
        >
          {post.author?.[0]}
        </Avatar>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="subtitle1" component="span">
            {post.author || 'Unknown Author'}
          </Typography>
          <CalendarTodayIcon fontSize="small" />
          <Typography variant="body2">{todayDate}</Typography>
        </Stack>
      </Stack>

      {post.image && (
        <CardMedia
          component="img"
          height="500"
          image={post.image}
          alt={post.title}
          sx={{ mb: 2 }}
        />
      )}
      <div dangerouslySetInnerHTML={{ __html: post.content }} />
    </Container>
  );
};

export default BlogDetail;
