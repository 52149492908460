import React, {useEffect}  from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container>
      <Grid
        container
        sx={{
          backgroundImage: "url(/images/bf11.webp)",
          backgroundSize: "cover",
          backgroundPosition: "center top",
          height: "75vh",
          width: "100%",
          marginTop: "5px",
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
        }}
      >
      </Grid>

      <Container maxWidth="md" sx={{ marginTop: "100px" }}>
        {/* Our Vision Section */}
        <Box
          my={4}
          style={{
            background: "#f0f0f0",
            padding: "20px",
            borderRadius: "10px",
              textAlign:isSmallScreen? 'left':'justify'
          }}
        >
          <Typography variant="h4" component="h2" gutterBottom>
            Our Vision
          </Typography>
          <Typography variant="body1">
            At Biophilin, we envision a world where advanced pharmaceutical
            solutions are accessible to all, transforming healthcare and
            improving lives. We aim to be a global leader in the pharmaceutical
            industry, recognized for our commitment to innovation, quality, and
            patient-centred care. Our vision is to drive progress in medicine
            through relentless research and development, delivering
            groundbreaking treatments that address the most pressing health
            challenges.
          </Typography>
        </Box>

        {/* Our Mission Section */}
        <Box
          my={4}
          style={{
            background: "#f9f9f9",
            padding: "20px",
            borderRadius: "10px",
            
          }}
        >
          <Typography variant="h4" component="h2" gutterBottom>
            Our Mission
          </Typography>
          <Typography variant="body1" >
            Our mission is to deliver high-quality pharmaceutical products and
            services that enhance patient health and well-being. We are
            dedicated to:
          </Typography>
          <List >
            <ListItem>
              <Typography variant="body1" style={{  textAlign:isSmallScreen? 'left':'justify'}}>
                <strong>Innovative Research and Development:</strong> We invest
                in cutting-edge research to discover and develop new medications
                that meet the evolving needs of patients and healthcare
                providers. Our focus is on creating innovative solutions that
                improve patient outcomes and quality of life.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1" style={{  textAlign:isSmallScreen? 'left':'justify'}}>
                <strong>Ensuring Safety and Efficacy:</strong> We are committed
                to the highest standards of safety and efficacy in all our
                products. Our rigorous testing and quality control processes
                ensure that every medication we produce meets stringent
                regulatory requirements.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1" style={{  textAlign:isSmallScreen? 'left':'justify'}}>
                <strong>Enhancing Access to Healthcare:</strong> We strive to
                make our pharmaceutical products accessible to a broad
                population, ensuring that patients everywhere have access to the
                medications they need. Our goal is to bridge gaps in healthcare
                access and provide solutions that are affordable and effective.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1" style={{  textAlign:isSmallScreen? 'left':'justify'}}>
                <strong>Educating and Empowering:</strong> We prioritize
                education for healthcare professionals and patients alike,
                providing the information and resources necessary to make
                informed decisions about health and treatments.
              </Typography>
            </ListItem>
          </List>
        </Box>

        {/* Our Values Section */}

        <Grid
          container
          spacing={4}
          sx={{
            padding: "40px 20px",
            backgroundColor: "#f9f9f9",
            marginTop: "20px",
            paddingLeft:isSmallScreen? '20px' : '0px'
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", alignItems: "center",  textAlign:isSmallScreen? 'left':'justify' }}
          >
            <Box>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", marginBottom: "16px", }}
              >
                Chairman's  Message
              </Typography>
              <Typography variant="body1" paragraph>
                It is with great pride and enthusiasm that I welcome you to
                Biophilin Pharmaceutical Company. As the Director, I am honoured
                to lead a team of dedicated professionals who are committed to
                making a significant impact in the field of healthcare through
                our innovative pharmaceutical products and services.
              </Typography>
              <Typography variant="body1" paragraph>
                At Biophilin, our journey began with a vision to create a
                pharmaceutical company that not only delivers high-quality
                medications but also transforms lives by addressing some of the
                most critical health challenges of our time. This vision
                continues to drive every aspect of our operations and is the
                cornerstone of our mission.
              </Typography>

              <Typography variant="body1" paragraph>
                We envision a world where advanced pharmaceutical solutions are
                accessible to all, transforming healthcare and improving lives.
                Our mission is to deliver high-quality pharmaceutical products
                and services that enhance patient health and well-being. This
                commitment is reflected in our focus on innovative research and
                development, ensuring safety and efficacy, enhancing access to
                healthcare, and prioritizing education and empowerment.
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src="/images/director (1).webp"
              alt="Director's Image"
              style={{
                borderRadius: "50%",
                width: "100%",
                height: "auto",
                maxWidth: "300px",
                margin: "0 auto",
                display: "block",
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
           
            sx={{ display: "flex", alignItems: "center", padding:'10px' }}
          >
          <Box
         
            style={{
              background: "#f0f0f0",
              // paddingleft:isSmallScreen? "50px": "20px",
              padding:'20px',
              borderRadius: "10px",
                textAlign:isSmallScreen? 'left':'justify'
            }}
          >
            <Typography variant="h4" component="h2" gutterBottom>
            Innovation and Quality
            </Typography>
            <Typography variant="body1">
            Innovation is at the heart of Biophilin. We invest heavily in cutting-edge research and
development to discover new medications and therapies that meet the evolving needs of patients
and healthcare providers. Our state-of-the-art laboratories and research facilities are staffed by
some of the brightest minds in the industry, all working tirelessly to bring groundbreaking
treatments to market.
            </Typography>
            <Typography variant="body1">
            Quality is non-negotiable at Biophilin. We adhere to the highest standards in every aspect of our
work, from research and development to manufacturing and distribution. Our rigorous testing
and quality control processes ensure that every product we produce meets stringent regulatory
requirements and delivers the efficacy and safety that our patients and partners expect.
            </Typography>
          </Box>
         </Grid>

         <Grid
            item
            xs={12}
           
            sx={{ display: "flex", alignItems: "center" }}
          >
          <Box
            style={{
              background: "#f0f0f0",
              // paddingleft:isSmallScreen? "50px": "20px",
              borderRadius: "10px",
              padding:'20px',
                textAlign:isSmallScreen? 'left':'justify'
            }}
          >
            <Typography variant="h4" component="h2" gutterBottom>
            Accessibility and Empowerment
            </Typography>
            <Typography variant="body1">
            We believe that access to effective healthcare should not be a privilege but a right. Therefore, we
are committed to making our pharmaceutical products accessible to a broad population. We
strive to bridge gaps in healthcare access, providing solutions that are both affordable and
effective, and ensuring that patients everywhere have access to the medications they need.
            </Typography>
            <Typography variant="body1">
            Education and empowerment are also central to our mission. We provide comprehensive
resources and support to healthcare professionals and patients, helping them make informed
decisions about treatments and health management. By fostering an environment of knowledge
and understanding, we empower individuals to take charge of their health and well-being.
            </Typography>
          </Box>
         </Grid>

          <Grid
            item
            xs={12}
           
            sx={{ display: "flex", alignItems: "center" }}
          >
          <Box
         
            style={{
              background: "#f0f0f0",
              // paddingleft:isSmallScreen? "50px": "20px",
              borderRadius: "10px",
              padding:'20px',
              textAlign:isSmallScreen? 'left':'justify'
            }}
          >
            <Typography variant="h4" component="h2" gutterBottom>
              Our Values
            </Typography>
            <Typography variant="body1">
              Our core values of integrity, innovation, quality, collaboration,
              and customer focus guide everything we do at Biophilin. We uphold
              the highest standards of ethics and transparency, ensuring trust
              and reliability in our products and services. We are committed to
              continuous innovation, leveraging the latest research and
              technology to develop cutting-edge pharmaceutical solutions. Our
              dedication to quality ensures that our products meet the highest
              standards of safety and efficacy. Collaboration is key to our
              success, and we work closely with healthcare professionals,
              researchers, and partners to drive advancements in medicine and
              improve patient outcomes. Finally, our customer-focused approach
              ensures that we understand and address the needs of our customers,
              providing personalized support and solutions.
            </Typography>
          </Box>
         </Grid>

              <Grid
      container
      sx={{
        // Styles for large screens
        backgroundImage: !isSmallScreen ? 'url(/images/looking.webp)' : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: 'auto',
        display: 'flex',
      padding: '20px',
        alignItems: 'center',
        marginTop: !isSmallScreen ? '40px' : '10px',
        // Styles for small screens
        [theme.breakpoints.down('sm')]: {
          backgroundImage: 'none',
          marginTop: '10px',
          height: 'auto', 
        },
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          textAlign: isSmallScreen? 'left':'right',
          paddingTop: '10px',
          marginTop: isSmallScreen? '20px':'-20px',
           padding: !isSmallScreen ? '40px' : '10px',
          [theme.breakpoints.down('sm')]: {
            paddingTop: '5px',
          },
        }}
      >
        <Typography variant="h4">Looking Ahead</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: !isSmallScreen ? '50%' : '100%',
            color: '#000000',
            padding: !isSmallScreen ? '40px' : '10px',
            marginTop: !isSmallScreen ? '20px' : '10px',
            textAlign:isSmallScreen? 'left': 'justify',
          }}
        >
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: { xs: '1rem', md: '1rem' } }}
          >
            As we look to the future, we remain steadfast in our commitment to transforming healthcare through innovative pharmaceutical solutions. We are excited about the opportunities ahead and confident in our ability to make a meaningful difference in the lives of patients around the world.
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: { xs: '1rem', md: '1rem' } }}
          >
            I extend my heartfelt thanks to our dedicated team, whose expertise, passion, and commitment are the driving force behind Biophilin's success. I also wish to express my gratitude to our partners, healthcare providers, and patients for their trust and support. Together, we are making significant strides in improving health outcomes and enhancing the quality of life for individuals and families.
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ fontSize: { xs: '1rem', md: '1rem' } }}
          >
            Thank you for being a part of the Biophilin journey. We look forward to continuing to serve you with excellence, innovation, and unwavering dedication.
          </Typography>
        </Box>
      </Grid>
    </Grid>
          

        </Grid>


        
      </Container>
    </Grid>
  );
};

export default AboutUs;

