import React, {useEffect} from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const headerStyle = {
  //   backgroundImage: "url(/images/bf4.jpg)",
  //   backgroundSize: "cover",
  //   backgroundPosition: "center top", 
  //   backgroundRepeat: "no-repeat",
  //   height: "75vh",
  //   width: "100%",
  //   margin: 0,
  //   padding: 0,
  //   marginTop: "5px",
  //   overflow: "hidden", 
  //   fontFamily:
  //     '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  //   WebkitFontSmoothing: "antialiased",
  //   MozOsxFontSmoothing: "grayscale",
  // };
  
  return (
    <Grid container>
        <img
          src="/images/bf4.jpg"
          alt="Blog"
          style={{
            width: '100%', 
            height: isSmallScreen ? 'auto' : '70vh',
            objectFit: 'cover',
            marginBottom: '10px',
            // display: 'block',
          }}
        />

      <Grid
        container
        sx={{ padding: "30px 20px", marginLeft: "20px", marginTop: "100px" }}
        justifyContent="center"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4">
              Send us{" "}
              <span style={{ color: "#003b69", fontWeight: "bold" }}>
                a message
              </span>
            </Typography>
            <form noValidate autoComplete="off">
              <TextField
                required
                id="name"
                label="Name"
                fullWidth
                margin="normal"
                sx={{ "& .MuiOutlinedInput-root": { borderRadius: "30px" } }}
              />
              <TextField
                required
                id="email"
                label="Email"
                type="email"
                fullWidth
                margin="normal"
                sx={{ "& .MuiOutlinedInput-root": { borderRadius: "30px" } }}
              />
              <TextField
                id="subject"
                label="Subject"
                fullWidth
                margin="normal"
                sx={{ "& .MuiOutlinedInput-root": { borderRadius: "30px" } }}
              />
              <TextField
                id="message"
                label="Message"
                multiline
                rows={3}
                fullWidth
                margin="normal"
                sx={{ "& .MuiOutlinedInput-root": { borderRadius: "50px" } }}
              />
              <Grid container justifyContent="center">
                <Button
                  variant="h2"
                  color="success"
                  type="submit"
                  sx={{
                    mt: 2,
                    borderRadius: "50px",
                    backgroundColor: "#003b69",
                    color: "#ffffff",
                    fontWeight: "bold",
                  }}
                >
                  SUBMIT
                </Button>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ textAlign: "center", marginBottom: '40px' }}
            >
              Contact{" "}
              <span style={{ color: "#003b69", fontWeight: "bold" }}>us</span>
            </Typography>

            
            <Grid container spacing={5}>
              <Grid
                item
                xs={12}
                sm={6}
                container
                alignItems="center"
                direction="column"
               
              >

                <Typography variant="body1" gutterBottom style={{marginLeft: '15px'}}>
                  <strong>Regional office(Marketing office)</strong>
                  <br />
                  No 26, New no 940, 3rd floor, 
                  <br/>
                  11th cross,Srinagar,
                  <br />
                   Bangalore - 560050
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                container
                alignItems="center"
                direction="column"
              >
                <img
                  src="/images/contact/celphone.png"
                  alt="Mobile"
                  style={{
                    width: "40px",
                    height: "40px",
                    marginBottom: "10px",
                  }}
                />
                <Typography variant="body1" gutterBottom>
                  <strong>Call us</strong>
                  <br />
                  Mobile - 9742541444
                  {/* <br />
                  Give us a free call 24/7 */}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                container
                alignItems="center"
                direction="column"
              >
                <Typography variant="body1" gutterBottom>
                  <strong>Head office(C&F)
                  </strong>
                  <br />
                  No 8493/1, Ground floor,  
                  <br />
                  Behind MDP coffee house,
                  <br/>Kannuralli road, Hoskote town, 
                  <br />
                  Bangalore - 562114
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                container
                alignItems="center"
                direction="column"
              >
                <img
                  src="/images/contact/email.png"
                  alt="Email"
                  style={{
                    width: "30px",
                    height: "30px",
                    marginBottom: "10px",
                  }}
                />
                <Typography variant="body1" gutterBottom>
                  <strong>Email us</strong>
                  <br />
                  blr@biophilin.in
                  <br />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contact;
// import React, {useEffect} from "react";
// import {
//   Grid,
//   Typography,
//   TextField,
//   Button,
//   useTheme,
//   useMediaQuery,
// } from "@mui/material";

// const Contact = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   const headerStyle = {
//     backgroundImage: "url(/images/bf4.jpg)",
//     backgroundSize: "cover",
//     backgroundPosition: "center top", 
//     backgroundRepeat: "no-repeat",
//     height: "75vh",
//     width: "100%",
//     margin: 0,
//     padding: 0,
//     marginTop: "5px",
//     overflow: "hidden", 
//     fontFamily:
//       '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
//     WebkitFontSmoothing: "antialiased",
//     MozOsxFontSmoothing: "grayscale",
//   };
  
//   return (
//     <Grid container>
//     <Grid container style={headerStyle}>
     
//       </Grid>

//       <Grid
//         container
//         sx={{ padding: "30px 20px", marginLeft: "20px", marginTop: "100px" }}
//         justifyContent="center"
//       >
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={7}>
//             <Typography variant="h4">
//               Send us{" "}
//               <span style={{ color: "#003b69", fontWeight: "bold" }}>
//                 a message
//               </span>
//             </Typography>
//             <form noValidate autoComplete="off">
//               <TextField
//                 required
//                 id="name"
//                 label="Name"
//                 fullWidth
//                 margin="normal"
//                 sx={{ "& .MuiOutlinedInput-root": { borderRadius: "30px" } }}
//               />
//               <TextField
//                 required
//                 id="email"
//                 label="Email"
//                 type="email"
//                 fullWidth
//                 margin="normal"
//                 sx={{ "& .MuiOutlinedInput-root": { borderRadius: "30px" } }}
//               />
//               <TextField
//                 id="subject"
//                 label="Subject"
//                 fullWidth
//                 margin="normal"
//                 sx={{ "& .MuiOutlinedInput-root": { borderRadius: "30px" } }}
//               />
//               <TextField
//                 id="message"
//                 label="Message"
//                 multiline
//                 rows={3}
//                 fullWidth
//                 margin="normal"
//                 sx={{ "& .MuiOutlinedInput-root": { borderRadius: "50px" } }}
//               />
//               <Grid container justifyContent="center">
//                 <Button
//                   variant="h2"
//                   color="success"
//                   type="submit"
//                   sx={{
//                     mt: 2,
//                     borderRadius: "50px",
//                     backgroundColor: "#003b69",
//                     color: "#ffffff",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   SUBMIT
//                 </Button>
//               </Grid>
//             </form>
//           </Grid>
//           <Grid item xs={12} md={5}>
//             <Typography
//               variant="h4"
//               gutterBottom
//               style={{ textAlign: "center" }}
//             >
//               Contact{" "}
//               <span style={{ color: "#003b69", fontWeight: "bold" }}>us</span>
//             </Typography>
//             <Grid container spacing={1}>
//               <Grid
//                 item
//                 xs={12}
//                 sm={6}
//                 container
//                 alignItems="center"
//                 direction="column"
//               >
//                 <img
//                   src="/images/contact/clock.png"
//                   alt="Clock"
//                   color="#003b69"
//                   style={{
//                     width: "70px",
//                     height: "70px",
//                     marginBottom: "10px",
//                   }}
//                 />
//                 <Typography variant="body1" gutterBottom>
//                   <strong>Working hours</strong>
//                   <br />
//                   MON-SAT | 9am to 6pm
//                   <br />
//                   SUN | 9am to 5pm
//                 </Typography>
//               </Grid>

//               <Grid
//                 item
//                 xs={12}
//                 sm={6}
//                 container
//                 alignItems="center"
//                 direction="column"
//               >
//                 <img
//                   src="/images/contact/celphone.png"
//                   alt="Mobile"
//                   style={{
//                     width: "60px",
//                     height: "60px",
//                     marginBottom: "10px",
//                   }}
//                 />
//                 <Typography variant="body1" gutterBottom>
//                   <strong>Call center</strong>
//                   <br />
//                   1-800-700-600
//                   <br />
//                   Give us a free call 24/7
//                 </Typography>
//               </Grid>

//               <Grid
//                 item
//                 xs={12}
//                 sm={6}
//                 container
//                 alignItems="center"
//                 direction="column"
//               >
//                 <img
//                   src="/images/contact/location.png"
//                   alt="Location"
//                   style={{
//                     width: "40px",
//                     height: "40px",
//                     marginBottom: "10px",
//                   }}
//                 />
//                 <Typography variant="body1" gutterBottom>
//                   <strong>Location</strong>
//                   <br />
//                   60 East 65th Street
//                   <br />
//                   New York City
//                 </Typography>
//               </Grid>

//               <Grid
//                 item
//                 xs={12}
//                 sm={6}
//                 container
//                 alignItems="center"
//                 direction="column"
//               >
//                 <img
//                   src="/images/contact/email.png"
//                   alt="Email"
//                   style={{
//                     width: "40px",
//                     height: "40px",
//                     marginBottom: "10px",
//                   }}
//                 />
//                 <Typography variant="body1" gutterBottom>
//                   <strong>Email us</strong>
//                   <br />
//                   info@biophilin.com
//                   <br />
//                 </Typography>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Grid>
//   );
// };

// export default Contact;
