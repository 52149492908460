import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Button, useTheme, useMediaQuery } from "@mui/material";
import RoundedTriangle from "./RoundedTriangle";

const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [formData, setFormData] = useState({
    name: "",
    emailid: "",
    mobileNumber: "",
    message: "",
    resume: null,
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    emailid: "",
    mobileNumber: "",
    resume: "",
  });

  // const headerStyle = {
  //   backgroundImage: 'url(/images/bf12.jpg)',
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  //   backgroundRepeat: 'no-repeat',
  //   height: '75vh',
  //   width: '100%',
  //   margin: 0,
  //   padding: 0,
  //   marginTop: '5px',
  //   fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  //   WebkitFontSmoothing: 'antialiased',
  //   MozOsxFontSmoothing: 'grayscale',
  // };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({
      ...formData,
      resume: file,
    });
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formData.emailid.trim()) {
      errors.emailid = "Email is required";
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.emailid)) {
      errors.emailid = "Invalid email format";
      isValid = false;
    }

    if (!formData.mobileNumber.trim()) {
      errors.mobileNumber = "Mobile number is required";
      isValid = false;
    } else if (!/^\d+$/.test(formData.mobileNumber)) {
      errors.mobileNumber = "Mobile number should contain only digits";
      isValid = false;
    }

    if (!formData.resume) {
      errors.resume = "Resume is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("emailid", formData.emailid);
      formDataToSend.append("mobileNumber", formData.mobileNumber);
      formDataToSend.append("message", formData.message);
      formDataToSend.append("resume", formData.resume);

      // Simulate sending the form data (replace with actual API call)
      console.log("Form data to send:", formDataToSend);

      // Clear form fields if needed
      setFormData({
        name: "",
        emailid: "",
        mobileNumber: "",
        message: "",
        resume: null,
      });
      setFormErrors({});
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <img
          src="/images/bf12.jpg"
          alt="Blog"
          style={{
            width: '100%', 
            height: isSmallScreen ? 'auto' : '70vh',
            objectFit: isSmallScreen ? 'contain' : 'cover',
            marginBottom: '10px',
          }}
        />
      </Grid>

      <Grid container sx={{ padding: '30px 20px', marginLeft: '20px', marginTop: '10px' }} justifyContent="center">
        <Grid container spacing={2} >
          <Grid item xs={12} md={6}>
            <Typography style={{ fontSize: '38px' }}>Join Us in <span style={{ color: '#003b69', fontWeight: 'bold' }}> Transforming Healthcare</span></Typography>
            <Typography style={{ fontSize: '18px', marginTop: '20px', marginBottom: '10px', textAlign:'justify' }}>At Biophilin Pharmaceutical Company, we are driven by a shared passion for innovation,
              excellence, and patient care. Our team is composed of talented individuals from diverse
              backgrounds, all working together to make a significant impact in the healthcare industry.
              Together, we can create a healthier future for all.</Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Typography>Name</Typography>
              <TextField
                required
                id="name"
                value={formData.name}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={!!formErrors.name}
                helperText={formErrors.name}
                sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' } }}
              />
              <Typography>Email Id</Typography>
              <TextField
                required
                id="emailid"
                value={formData.emailid}
                onChange={handleInputChange}
                type="email"
                fullWidth
                margin="normal"
                error={!!formErrors.emailid}
                helperText={formErrors.emailid}
                sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' } }}
              />
              <Typography>Mobile Number</Typography>
              <TextField
                required
                id="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                error={!!formErrors.mobileNumber}
                helperText={formErrors.mobileNumber}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' } }}
              />
              <Typography>Message</Typography>
              <TextField
                id="message"
                value={formData.message}
                onChange={handleInputChange}
                multiline
                rows={3}
                fullWidth
                margin="normal"
                sx={{ '& .MuiOutlinedInput-root': { borderRadius: '10px' } }}
              />
              <Typography>Your Resume</Typography>
              <label htmlFor="upload-resume">
                <input
                  accept=".pdf,.doc,.docx"
                  style={{ display: 'none' }}
                  id="upload-resume"
                  type="file"
                  onChange={handleFileChange}
                />
                <Button
                  variant="outlined"
                  component="span"
                  fullWidth
                  sx={{ textTransform: 'none', '& .MuiButton-label': { justifyContent: 'start' } }}
                >
                  Choose File
                </Button>
              </label>
              {formErrors.resume && (
                <Typography variant="body2" color="error" sx={{ marginTop: '5px' }}>
                  {formErrors.resume}
                </Typography>
              )}
              <Grid container justifyContent="center">
                <Button variant="contained" color="success" type="submit" sx={{ mt: 5, borderRadius: '50px', backgroundColor: '#003b69' }}>
                  Submit Your CV
                </Button>
              </Grid>
            </form>
          </Grid>
          {/* Adding the RoundedTriangle component to the right side */}
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
          src="/images/bf9.jpg" 
          style={{
            Width:isSmallScreen? '200px': '400px',
            height:isSmallScreen? '200px': '400px'
          }}
          />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Careers;
