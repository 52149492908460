import React, { useEffect } from "react";
import { Grid, Typography, useTheme, useMediaQuery, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, styled } from "@mui/material";

const StyledTableContainer = styled(TableContainer)({
  maxHeight: '600px',
  margin: '0 auto', // Center the table horizontally
});

const StyledTableHead = styled(TableHead)({
  backgroundColor: '#f5f5f5',

});

const StyledTableCell = styled(TableCell)({
  fontWeight: 'bold',
  fontSize: '20px',
});

const ProductList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const products = [
    { id: 1, title: 'Augumox cv 625' },
    { id: 2, title: 'Augumox cv forte' },
    { id: 3, title: 'Augumox cv forte 60ml' },
    { id: 4, title: 'Pandop 40mg' },
    { id: 5, title: 'Pandop DSR' },
    { id: 6, title: 'Montbery LC' },
    { id: 7, title: 'Montbery LC Kid' },
    { id: 8, title: 'Vitamilin-AZ' },
    { id: 9, title: 'Mefbery P' },
    { id: 10, title: 'Berymol ASP' },
    { id: 11, title: 'Berymol 250' },
    { id: 12, title: 'Zincbery' },
    { id: 13, title: 'Amroline Plus' },
    { id: 14, title: 'Amroline LS' },
    { id: 15, title: 'Amroline XP' },
    { id: 16, title: 'Pulmobery' },
    { id: 17, title: 'Berysod' },
    { id: 18, title: 'Calciolin' },
    { id: 19, title: 'Lanzbery 15mg' },
    { id: 20, title: 'Rabilight O' },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <img
          src="/images/bf1.jpg"
          alt="Blog"
          style={{
            width: '100%', 
            height: isSmallScreen ? 'auto' : '70vh',
            objectFit: isSmallScreen ? 'contain' : 'cover',
            marginBottom: '10px',
          }}
        />
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1, justifyContent: 'center' }}>
        <Grid item xs={12} md={7}>
          <Typography sx={{ textAlign: 'center', fontSize: 30, fontWeight: 'bold', mb:3 }}>PRODUCTS</Typography>
      
        <Grid item xs={12} md={6} style={{justifyItems:'center',marginTop: '30px',marginBottom: '20px',display: 'center',padding: '20px', marginLeft:isSmallScreen? '20px': '0px'}}>
          <StyledTableContainer component={Paper}>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell>{product.id}</TableCell>
                    <TableCell>{product.title}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductList;





// import React from "react";
// import { Grid, Typography, Divider, useTheme, useMediaQuery } from "@mui/material";
// import { List, Avatar, Box } from '@mui/material';
// import { styled } from '@mui/system';
// import { useEffect } from "react";

// const ProductList = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

//   const products = [
//     {
//       id: 1,
//       title: 'Augumox cv 625',
//       description: 'Description for product 1',
//       imageUrl: '/images/bf5.jpg',
//     },
//     {
//       id: 2,
//       title: 'Augumox cv forte',
//       description: 'Description for product 2',
//       imageUrl: 'images/bf6.jpg',
//     },
//     {
//       id: 3,
//       title: 'Augumox cv 625 forte 60ml',
//       description: 'Description for product 3',
//       imageUrl: 'images/bf8.jpg', 
//     },
//     {
//       id: 4,
//       title: 'Pandop 40mg',
//       description: 'Description for product 4',
//       imageUrl: 'images/bf2.jpg', 
//     },
//     {
//       id: 5,
//       title: 'Pandop DSR',
//       description: 'Description for product 1',
//       imageUrl: '/images/bf5.jpg',
//     },
//     {
//       id: 6,
//       title: 'Montbery LC',
//       description: 'Description for product 2',
//       imageUrl: 'images/bf6.jpg',
//     },
//     {
//       id: 7,
//       title: 'Montbery LC Kid',
//       description: 'Description for product 3',
//       imageUrl: 'images/bf8.jpg', 
//     },
//     {
//       id: 8,
//       title: 'Vitamilin-AZ',
//       description: 'Description for product 4',
//       imageUrl: 'images/bf2.jpg', 
//     },
//     {
//       id: 9,
//       title: 'Mefbery P',
//       description: 'Description for product 1',
//       imageUrl: '/images/bf5.jpg',
//     },
//     {
//       id: 10,
//       title: 'Berymol ASP',
//       description: 'Description for product 2',
//       imageUrl: 'images/bf6.jpg',
//     },
//     {
//       id: 11,
//       title: 'Berymol 250',
//       description: 'Description for product 3',
//       imageUrl: 'images/bf8.jpg', 
//     },
//     {
//       id: 12,
//       title: 'Zincbery',
//       description: 'Description for product 4',
//       imageUrl: 'images/bf2.jpg', 
//     },
//     {
//       id: 13,
//       title: 'Amroline Plus',
//       description: 'Description for product 1',
//       imageUrl: '/images/bf5.jpg',
//     },
//     {
//       id: 14,
//       title: 'Amroline LS',
//       description: 'Description for product 2',
//       imageUrl: 'images/bf6.jpg',
//     },
//     {
//       id: 15,
//       title: 'Amroline XP',
//       description: 'Description for product 3',
//       imageUrl: 'images/bf8.jpg', 
//     },
//     {
//       id: 16,
//       title: 'Pulmobery',
//       description: 'Description for product 4',
//       imageUrl: 'images/bf2.jpg', 
//     },
//     {
//       id: 17,
//       title: 'Berysod',
//       description: 'Description for product 1',
//       imageUrl: '/images/bf5.jpg',
//     },
//     {
//       id: 18,
//       title: 'Calciolin',
//       description: 'Description for product 2',
//       imageUrl: 'images/bf6.jpg',
//     },
//     {
//       id: 19,
//       title: 'Lanzbery 15mg',
//       description: 'Description for product 3',
//       imageUrl: 'images/bf8.jpg', 
//     },
//     {
//       id: 20,
//       title: 'Rabilight O',
//       description: 'Description for product 4',
//       imageUrl: 'images/bf2.jpg', 
//     },
//   ];

//   const ProductImage = styled(Avatar)({
//     width: '250px',
//     height: '250px',
//     margin: '0 auto',
//   });

//   const ListItemWrapper = styled(Box)({
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     border: '1px solid #ccc',
//     borderRadius: '8px',
//     padding: '16px',
//     marginBottom: '24px',
//     width: '100%',
//   });

//   const ListItemTextWrapper = styled(Box)({
//     textAlign: 'center',
//     marginTop: '16px',
//   });

//   return (
//     <Grid container>
//     <Grid item xs={12}>
//     <img
//           src="/images/bf1.jpg"
//           alt="Blog"
//           style={{
//             width: '100%', 
//             height: isSmallScreen ? 'auto' : '70vh',
//             objectFit:isSmallScreen? 'contain': 'cover',
//             marginBottom: '10px',
//             // display: 'block',
//           }}
//         />
//       </Grid>
//       <Grid container spacing={2} sx={{ mt: 10, justifyContent: 'center', pd: 10 }}>
//         <Grid item xs={12} md={6}>
//           <Typography sx={{ textAlign: 'center', fontSize: 30, fontWeight: 'bold', mb: 10 }}>PRODUCTS</Typography>
//         </Grid>
//         <List sx={{ width: '80%' }}>
//           <Grid container spacing={8} >
//             {products.map((product) => (
//       <Grid item xs={12} sm={6} md={4} key={product.id} sx={{mr:isSmallScreen? 2:0}}>
//                 <ListItemWrapper>
//                   <ProductImage variant="square" src={product.imageUrl} />
//                   <Divider variant="middle" sx={{ width: '100%', margin: '16px 0' }} />
//                   <ListItemTextWrapper>
//                     <Typography variant="h6">{product.title}</Typography>
//                     <Typography variant="body2">{product.description}</Typography>
//                   </ListItemTextWrapper>
//                 </ListItemWrapper>
//               </Grid>
//             ))}
//           </Grid>
//         </List>
//       </Grid>
//     </Grid>
//   );
// };

// export default ProductList;


 // const headerStyle = {
  //   backgroundImage: 'url(/images/bf1.jpg)',
  //   backgroundSize: 'cover',
    
  //   backgroundPosition: 'center top',
  //   backgroundRepeat: 'no-repeat',
  //   height: '75vh',
  //   width: '100%',
  //   margin: 0,
  //   padding: 0,
  //   marginTop: '5px',
  //   fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  //   WebkitFontSmoothing: 'antialiased',
  //   MozOsxFontSmoothing: 'grayscale',
  // };