// import { BrowserRouter } from 'react-router-dom';
// import { createRoot } from 'react-dom'; // Import createRoot
// import { StyledEngineProvider } from '@mui/material';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// // Use createRoot instead of ReactDOM.render
// const root = createRoot(document.getElementById('root'));

// root.render(
//   <StyledEngineProvider injectFirst>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </StyledEngineProvider>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react';
import { createRoot } from 'react-dom/client';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

const root = createRoot(document.getElementById('root'));

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </StyledEngineProvider>
);

reportWebVitals();
