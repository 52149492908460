

import React, {useEffect} from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import PostCard from './PostCard'; 
import blogContent from './blogContent';

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));

  return (
    <Grid container spacing={0} sx={{ p: 0, m: 0 }}>
      <Grid item xs={12}>
        <img
          src="/images/blog.jpg"
          alt="Blog"
          style={{
            width: '100%', 
            height: isSmallScreen ? 'auto' : '70vh',
            objectFit: 'cover',
            marginBottom: '10px',
            // display: 'block',
          }}
        />
      </Grid>

      <Grid container spacing={1} sx={{ mt: isSmallScreen ? 0 : 2 }}>
        {blogContent.map(post => (
          <Grid item xs={12} sm={6} md={4} key={post.id}>
            <PostCard post={post} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Blogs;
