import React, { useEffect } from "react"; // Import useEffect
import { Grid } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Navbar from "./Navbar";
import Home from "./screens/Home";
import Contact from "./screens/Contact";
import Careers from "./screens/Career";
import NewsandEvents from "./screens/News&Events";
import Product from "./screens/Product";
import Partners from "./screens/Partners";
import Blogs from "./screens/Blogs";
import AboutUs from "./screens/AboutUs";
import Footer from "./footer";
import BlogDetail from "./screens/BlogDetail";

function App() {
 

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/products" element={<Product />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/" element={<Blogs />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/post/:title" element={<BlogDetail />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
