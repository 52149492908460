import React from 'react';
import { Container, Typography, Grid, Paper, TableCell, TableBody, TableRow, TableContainer, TableHead, Table, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { partnersdata } from './data'; 

const Partners = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    const Header = styled(Typography)(({ theme }) => ({
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: theme.spacing(10),
    }));

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        borderTop: `2px solid ${theme.palette.divider}`,
        borderBottom: `2px solid ${theme.palette.divider}`,
         padding: theme.spacing(3), 
        textAlign: 'left', 
        fontSize: '20px',
        fontWeight: 'bold'  
    }));

    const StyledTableRow = styled(TableRow)(({ index }) => ({
        backgroundColor: index % 2 === 0 ? '#ffffff' : '#f0f8ff',
        
    }));

    return (
        <Grid container>
            <Grid item xs={12}>
                <img
                    src="/images/bf10.jpg"
                    style={{
                        width: '100%', 
                        height: isSmallScreen ? 'auto' : '70vh',
                        objectFit: isSmallScreen ? 'contain' : 'cover',
                        marginBottom: '10px',
                    }}
                />
            </Grid>
            
            <Grid container spacing={2} sx={{ marginTop: '100px', justifyContent: 'center' }}>
                <Grid item xs={12} md={7}>
                    <Header variant="h4" component="h1">
                        Manufacturing Partners
                    </Header>
                </Grid>

                <Grid item xs={12} md={6} style={{ marginBottom: '50px', padding: '20px', marginLeft:isSmallScreen? '20px': '0px' }}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Sl. No.</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {partnersdata.map((partner, index) => (
                                    <StyledTableRow key={partner.id} index={index}>
                                    <TableCell sx={{fontSize:16}}>{partner.id}</TableCell>
                                    <TableCell sx={{fontSize:16}}>{partner.name}</TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Partners;


// import React from 'react';
// import { Container, Typography, Grid, Paper, Box, useTheme, useMediaQuery, TableCell, TableBody,TableRow,TableContainer,TableHead,Table } from '@mui/material';
// import { styled } from '@mui/system';
// import { partnersdata } from './data'; 


// const Partners = () => {
//     const theme = useTheme();
//     const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
//     const Header = styled(Typography)(({ theme }) => ({
//         color: theme.palette.primary.main,
//         // fontWeight: 600,
//         // marginBottom: '20px',
//         // textAlign: 'center',
//         textAlign: 'center', fontWeight: 'bold', mb: 10
//     }));
  

//     return (
//       <Grid container>
//     <Grid item xs={12}>
//     <img
//           src="/images/bf10.jpg"
//           // alt="Blog"
//           style={{
//             width: '100%', 
//             height: isSmallScreen ? 'auto' : '70vh',
//             objectFit:isSmallScreen? 'contain': 'cover',
//             marginBottom: '10px',
//             // display: 'block',
//           }}
//         />
//       </Grid>
      
//         <Grid container spacing={2} sx={{ marginTop: '100px', justifyContent: 'center' }}>

//         <Grid item xs={12} md={7}>
//           <Header variant="h4" component="h1" sx={{ textAlign: 'center', fontWeight: 'bold', mb: 10 }}>
//                 Manufacturing Partners
//             </Header>
//         </Grid>


//         <Grid item xs={12} md={6} style={{marginBottom: '50px', padding:'20px'}}>
// <TableContainer component={Paper}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>ID</TableCell>
//             <TableCell>Name</TableCell>
           
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {partnersdata.map((partner) => (
//             <TableRow key={partner.id}>
//               <TableCell>{partner.id}</TableCell>
//               <TableCell>{partner.name}</TableCell>
            
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//     </Grid>
//       </Grid>
//         </Grid>
//     );
// };

// export default Partners;
    // const headerStyle = {
    //     backgroundImage: 'url(/images/bf10.jpg)',
    //     backgroundSize: 'cover',
    //     backgroundPosition: 'center',
    //     backgroundRepeat: 'no-repeat',
    //     height: '75vh',
    //     width: '100%',
    //     margin: 0,
    //     padding: 0,
    //     marginTop: '5px',
    //     fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    //     WebkitFontSmoothing: 'antialiased',
    //     MozOsxFontSmoothing: 'grayscale',
    //   };
    // <PageContainer maxWidth="md"></PageContainer>


        {/* <PageContainer maxWidth="lg">

            <Grid container spacing={5}>
                {partnersdata.map((partner) => (
                    <Grid item xs={12} sm={6} md={4} key={partner.id}>
                        <PartnerCard elevation={3}>
                            <PartnerName variant="h6">
                                {partner.name}
                            </PartnerName>
                            <Box mt={2}>
                                <PartnerAddress variant="body1">
                                    {partner.address}
                                </PartnerAddress>
                            </Box>
                        </PartnerCard>
                    </Grid>
                ))}
            </Grid>
        </PageContainer> */}

        // const PageContainer = styled(Container)(({ theme }) => ({
        //     marginTop: theme.spacing(4),
        //     marginBottom: theme.spacing(4),
        //     fontFamily: 'Roboto, sans-serif',
        //     backgroundColor: '#f5f5f5',
        //     padding: '10px',
        // }));
        

        
        // const PartnerCard = styled(Paper)(({ theme }) => ({
        //     padding: theme.spacing(2),
        //     textAlign: 'center',
        //     color: theme.palette.text.secondary,
        //     borderRadius: '12px',
        //     marginLeft:isSmallScreen? '15px':'0px'
        // }));
        
        // const PartnerName = styled(Typography)(({ theme }) => ({
        //     fontSize: '1.5rem',
        //     fontWeight: 'bold',
        //     color: theme.palette.primary.main,
        // }));
        
        // const PartnerAddress = styled(Typography)(({ theme }) => ({
        //     fontSize: '1rem',
        //     color: theme.palette.text.secondary,
        // }));