import React,{useEffect} from "react";
import {
  Grid,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const appStyle = {
    backgroundImage: "url(/images/bf1.webp)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "90vh",
    width: "100%",
    margin: 0,
    padding: 0,
    marginTop: "5px",
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
  };

  const steps = [
    {
      image: "/images/exp.webp",
      alt:'exp',
      title: "Expert Medical Solutions ",
      description:
        "Our specialists are leaders in their fields, providing expert pharmaceutical products and services.",
      step: 1,
    },
    {
      image: "/images/inn.webp",
      alt: 'inn',
      title: "Innovation-Driven Approach",
      description:
        " We prioritize research and development, ensuring that our products are based on the latest scientific advancements.",
      step: 2,
    },
    {
      image: "/images/comm.webp",
      alt:'comm',
      title: "Commitment to Quality",
      description:
        " We adhere to strict quality standards to ensure you receive the best possible pharmaceutical products.",
      step: 3,
    },
    {
      image: "/images/art1.webp",
      alt: 'art1',
      title: "State-of-the-Art Facilities",
      description:
        " Our collaborations with pioneering manufacturing partners with modern facilities are equipped with the latest technology to deliver top-notch pharmaceutical solutions.",
      step: 5,
    },
    {
      image: "/images/comp.webp",
      alt: 'comp',
      title: "Comprehensive Product Range",
      description:
        " From routine medications  specialized treatments, we offer a wide range of products to address various healthcare needs.",
      step: 4,
    },
  ];

  const envelopeContainerStyle = {
    position: "relative",
    width: "350px",
    height: "auto",
    margin: "20px auto",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    overflow: "visible",
  };

  const hexagonStyle = {
    position: "absolute",
    top: "-86.6px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "350px",
    height: "350px",
    backgroundColor: "#f0f0f0",
    clipPath: "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
    overflow: "hidden",
    zIndex: 1,
  };

  const hexagonImageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  const rectangleStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    overflow: "visible",
    paddingTop: "86.6px",
    marginBottom: "70px",
  };

  const contentStyle = {
    padding: "20px",
    paddingTop: "250px",
  };

  return (
    <Grid container>
      <Grid container style={appStyle}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#003b69",
            fontWeight: "bold",
            padding: "20px",
          }}
        >
          <Typography
            variant="h3"
            sx={{ fontSize: { xs: "2rem", md: "4rem" } }}
          >
            Welcome to Biophilin Pharmaceuticals
          </Typography>
        </Grid>

      </Grid>

      <Grid
        container
        style={{
          backgroundColor: "#ffffff",
          marginTop: "-1px",
          padding: "20px",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "auto",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <svg width="0" height="0">
              <defs>
                <clipPath
                  id="roundedTriangle"
                  clipPathUnits="objectBoundingBox"
                >
                  <path d="M 0.5 0 Q 0.95 0.3 0.8 1 Q 0.2 1 0.05 0.3 Q 0.5 0 0.5 0" />
                </clipPath>
              </defs>
            </svg>
            <div
              style={{
                width: "100%",
                height: "auto",
                clipPath: "url(#roundedTriangle)",
                transform: "rotate(-130deg)",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  transform: "rotate(130deg)",
                }}
              >
                <img
                  src="/images/bf11.webp"
                  alt="Pharmaceutical"
                  style={{
                    width: "85%",
                    height: "auto",
                     objectFit: "cover",
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} style={{ marginTop: "70px", paddingRight:isSmallScreen?'5px':'40px' }}>
          <Typography style={{ fontSize: "24px", textAlign:isSmallScreen? 'left': 'justify' }}>
            At Biophilin, we are dedicated to advancing healthcare through our
            commitment to quality, innovation, and excellence. As a leading
            pharmaceutical company, we specialize in a diverse range of medical
            fields to meet the comprehensive needs of our community. Our mission
            is to revolutionize healthcare by providing exceptional medical
            services and innovative solutions. Established with a vision to
            enhance the quality of life for individuals and families, Biophilin
            strives to grow into a trusted name in the pharmaceutical industry,
            renowned for our dedication to patient care and medical excellence.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        sx={{
          padding: "40px",
          marginTop: "40px",
          justifyContent: "center",
          backgroundColor: "#003b69",
          color: "black",
          minHeight: "calc(110vh - 100px)",
          textAlign:isSmallScreen? 'center':'justify'
        }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          sx={{ ml: { xs: "0", sm: "150px" }, mt: "30px" }}
        >
          <Typography
            sx={{
              fontSize: { xs: "24px", sm: "30px" },
              fontWeight: "bold",
              mb: "10px",
              color: "white",
            }}
          >
            Our Values
          </Typography>
          <Typography
            sx={{ fontSize: { xs: "16px", sm: "20px" }, color: "white" }}
          >
            At Biophilin, we adhere to a set of core values that guide our
            operations and interactions with our partners, healthcare providers,
            and patients:
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Paper
                sx={{
                  padding: "20px",
                  height: "100%",
                  border: "1px solid grey",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#3C3B3B",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      marginRight: "20px",
                    }}
                    src="/images/values/integrity.png"
                    alt="integrity"
                  />
                  Integrity
                </Typography>
                <Typography sx={{ color: "#5c5c5b" }}>
                  We uphold the highest standards of ethics and transparency in
                  all our practices, ensuring trust and reliability in our
                  products and services.
                </Typography>
              </Paper>
            </Grid>
            <Grid item>
              <Paper
                sx={{
                  padding: "20px",
                  height: "auto",
                  border: "1px solid grey",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#3C3B3B",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      marginRight: "20px",
                    }}
                    src="/images/values/quality.webp"
                    alt="quality"
                  />
                  Quality
                </Typography>
                <Typography sx={{ color: "#5c5c5b" }}>
                  We strive for excellence in every aspect of our work, from
                  research and development to manufacturing and distribution,
                  ensuring that our products meet the highest standards of
                  safety and efficacy.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        {/* Middle: 1 box */}
        <Grid item xs={12} sm={3} sx={{ mt: { xs: "20px", sm: "100px" } }}>
          <Paper sx={{ padding: "20px", border: "1px solid", color: "black" }}>
            <Typography
              variant="h5"
              sx={{
                color: "#3C3B3B",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <img
                style={{ width: "30px", height: "30px", marginRight: "20px" }}
                src="/images/values/innovation.webp"
                alt="innovation"
              />
              Innovation
            </Typography>
            <Typography sx={{ color: "#5c5c5b" }}>
              We are committed to continuous innovation, leveraging the latest
              research and technology to develop cutting-edge pharmaceutical
              solutions that meet the evolving needs of the healthcare industry.
            </Typography>
          </Paper>
        </Grid>

        {/* Right side: 2 boxes */}
        <Grid item xs={12} sm={3}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Paper
                sx={{ padding: "20px", border: "1px solid", color: "black" }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#3C3B3B",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      marginRight: "20px",
                    }}
                    src="/images/values/collaboration.webp"
                    alt="collaboration"
                  />
                  Collaboration
                </Typography>
                <Typography sx={{ color: "#5c5c5b" }}>
                  We believe in the power of collaboration, working closely with
                  healthcare professionals, researchers, and partners to drive
                  advancements in medicine and improve patient outcomes.
                </Typography>
              </Paper>
            </Grid>
            <Grid item>
              <Paper
                sx={{ padding: "20px", border: "1px solid", color: "black" }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#3C3B3B",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      marginRight: "20px",
                    }}
                    src="/images/values/customerfocus.webp"
                    alt="customerfocus"
                  />
                  Customer Focus
                </Typography>
                <Typography sx={{ color: "#5c5c5b" }}>
                  We are dedicated to understanding and addressing the needs of
                  our customers, providing personalized support and solutions to
                  enhance their health and well-being.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ position: "relative", mt: "20px" }}>
          <img
            src="/images/bottom_cover_3.webp"
            alt="Bottom Cover"
            style={{
              position: "absolute",
              maxWidth: "100%",
              height: "auto",
              bottom: "-85px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{ marginTop: "50px", marginBottom: "70px" }}
      >
        <Typography
          sx={{
            marginLeft: isSmallScreen ? "10px" : "-30px",
            fontSize: "30px",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Why Choose Biophilin?
        </Typography>
        <Typography
          sx={{
            marginLeft: isSmallScreen ? "10px" : "0",
            fontSize: "20px",
            marginBottom: "40px",
          }}
        >
          Choosing Biophilin means partnering with a pharmaceutical company that
          genuinely cares about your health. We offer:
        </Typography>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ padding: "20px", marginTop: isSmallScreen ? "20px" : "60px" }}
        >
          {steps.map((step, index) => (
            <Grid
              item
              key={index}
              sx={{ textAlign: "center", margin: "0 10px" }}
            >
              <div style={envelopeContainerStyle}>
                <div style={rectangleStyle}>
                  <div style={hexagonStyle}>
                    <img style={hexagonImageStyle} src={step.image} alt={step.alt} />
                  </div>
                  <div style={contentStyle}>
                    <Typography
                      sx={{
                        marginBottom: "30px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#3C3B3B",
                      }}
                    >
                      {step.title}
                    </Typography>
                    <Typography sx={{ color: "#5c5c5b" }}>
                      {step.description}
                    </Typography>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
