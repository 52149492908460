import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Facebook, Instagram, Twitter, Phone, Email } from "@mui/icons-material";

const footerStyle = {
  background: "#000d2d",
  color: "white",
  marginTop: "60px",
  overflowX: "hidden",
};

const listItemStyle = {
  listStyle: "none",
  marginBottom: '15px',
  paddingLeft: '40px',
};

const currentYear = new Date().getFullYear();

const Footer = () => {
  const [hoveredLink, setHoveredLink] = useState(null);
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <footer style={footerStyle}>
      <Grid container justifyContent="center">
        <Grid
          container
          item
          lg={9}
          md={10}
          sm={11}
          xs={12}
          spacing={2}
          style={{ marginTop: "20px" }}
          alignItems="center"
          textAlign="left"
        >
          <Grid item lg={3} md={6} sm={6} xs={12} style={{ marginLeft: isMobile ? '10px' : '0' }}>
            <Link to="/">
              <img
                src="/images/bio_logo.jpg"
                alt="Biophilin Logo"
                style={{ height: "60px", width: "120px", marginRight: "15px" }}
              />
            </Link>
            <p>
              Elevate your pharmaceutical marketing with Pharmit cutting-edge
              software, optimising workflows, driving data-driven decisions, and
              ensuring unmatched industry growth.
            </p>
          </Grid>

          <Grid item lg={3} md={6} sm={6} xs={12}>
            <h3
              style={{
                paddingLeft: "40px",
              }}
            >
              Our Company
            </h3>
            <ul style={listItemStyle}>
              {[
                { text: "Home", path: "/" },
                { text: "AboutUs", path: "/about" },
                { text: "Product", path: "/products" },
                { text: "Manufacturing Partners", path: "/manufacturing-partners" },
                { text: "Blogs", path: "/blogs" },
                { text: "Careers", path: "/careers" },
                { text: "Contact", path: "/contact" },
              ].map((item, index) => (
                <li
                  key={index}
                  style={{
                    fontSize: "16px",
                    listStyle: "none",
                    marginBottom: '15px',
                  }}
                >
                  <Link
                    style={{
                      color: "white",
                      textDecoration: "none",
                      borderBottom: "1px solid transparent",
                      transition: "border-color 0.3s ease",
                    }}
                    to={item.path}
                    onMouseOver={(e) => (e.target.style.borderColor = "white")}
                    onMouseOut={(e) => (e.target.style.borderColor = "transparent")}
                  >
                    {item.text}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>

          <Grid item lg={3} md={6} sm={6} xs={12}>
            <h3
              style={{
                paddingLeft: isMobile ? "10px" : "0px",
                marginLeft: "40px",
                marginTop: isMobile? '30px': '-130px'
              }}
            >
              For Sales
            </h3>
            <ul style={listItemStyle}>
              <li
                style={{ color: "white", fontSize: "16px", marginLeft: "10px", marginBottom: '15px' }}
              >
                <Phone
                  style={{ verticalAlign: "middle", marginRight: "8px" }}
                />
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="tel:+919742541444"
                >
                  +91 97425 41444
                </a>
              </li>
              <li
                style={{ fontSize: "16px", color: "white", marginLeft: "10px" }}
              >
                <Email
                  style={{ verticalAlign: "middle", marginRight: "8px" }}
                />
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="mailto:sales@elitespower.com"
                >
                  Email: Blr@biophilin.in
                </a>
              </li>
            </ul>
          </Grid>

          <Grid item lg={3} md={6} sm={6} xs={12} style={{ marginBottom: '20px', }}>
            <h3
              style={{
                paddingLeft: isMobile ? "10px" : "0px",
                marginTop: isMobile ? "20px" : "-120px",
                marginLeft: "40px",
              }}
            >
              Follow Us
            </h3>
            {[
              {
                icon: <Facebook style={{ color: 'white' }} />,
                text: "biophilin",
                link: "https://www.facebook.com/profile.php?id=61555965698271",
              },
              {
                icon: <Instagram style={{ color: 'white' }} />,
                text: "@biophilin",
                link: "https://www.instagram.com/byte_crafts?igsh=MXQzcWV2enQ2eHBxcg==",
              },
              {
                icon: <Twitter style={{ color: 'white' }} />,
                text: "@biophilin",
                link: "https://www.twitter.com/profile.php?id=61555965698271",
              },
            ].map(({ icon, text, link }, index) => (
              <Grid
                container
                alignItems="center"
                key={index}
                style={{ marginLeft: "45px" }}
              >
                <Grid item>
                  <Link
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="inherit"
                    marginBottom="10px"
                  >
                    {icon}
                  </Link>
                </Grid>
                <Grid item>
                  <Typography marginLeft="5px">{text}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Divider
          style={{
            backgroundColor: "white",
            marginTop: "30px",
            marginBottom: "10px",
            width: "80%",
          }}
        />

        <Grid
          container
          style={{
            minHeight: "10vh",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            flexDirection: isMobile ? "row" : "column",
            textAlign: isMobile ? "center" : "left",
          }}
        >
          <Grid item xs={12}>
            <Typography variant="body2" component="p">
              © {currentYear} Biophilin Pharmaceuticals. All rights reserved.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <a
              href="https://bytecrafts.in"
              style={{ color: "#ffffff", textDecoration: "underline" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Developed and Designed by Bytecrafts
            </a>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
