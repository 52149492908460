// src/data.js
export const partnersdata = [
    {
        id: 1,
        name: 'Malik lifesciences Pvt Ltd',
        address: '1234 Rajajinagar, City, Country'
    },
    {
        id: 2,
        name: 'Malik lifesciences Pvt Ltd',
        address: '5678 Vidyanagar, City, Country'
    },
    {
        id: 3,
        name: 'Malik lifesciences Pvt Ltd',
        address: '1234 Rajajinagar, City, Country'
    },
    {
        id: 4,
        name: 'Pure & Cure Healthcare Pvt. Ltd.',
        address: '5678 Vidyanagar, City, Country'
    },
    {
        id: 5,
        name: 'Pure & Cure Healthcare Pvt. Ltd.',
        address: '1234 Rajajinagar, City, Country'
    },
    {
        id: 6,
        name: 'Pure & Cure Healthcare Pvt. Ltd.',
        address: '5678 Vidyanagar, City, Country'
    },
    {
        id: 7,
        name: 'Akums Drugs & Pharmaceuticals Ltd.',
        address: '1234 Rajajinagar, City, Country'
    },
    {
        id: 8,
        name: 'Akums Drugs & Pharmaceuticals Ltd.',
        address: '5678 Vidyanagar, City, Country'
    },
    {
        id: 9,
        name: 'Akums Drugs & Pharmaceuticals Ltd.',
        address: '5678 Vidyanagar, City, Country'
    },
    {
        id: 10,
        name: 'Life Vision Healthcare',
        address: '1234 Rajajinagar, City, Country'
    },
    {
        id: 11,
        name: 'Life Vision Healthcare',
        address: '5678 Vidyanagar, City, Country'
    },
    {
        id: 12,
        name: 'Life Vision Healthcare',
        address: '1234 Rajajinagar, City, Country'
    },
    {
        id: 13,
        name: 'Life Vision Healthcare',
        address: '5678 Vidyanagar, City, Country'
    },
    {
        id: 14,
        name: 'Life Vision Healthcare',
        address: '1234 Rajajinagar, City, Country'
    },
    {
        id: 15,
        name: 'Life Vision Healthcare',
        address: '5678 Vidyanagar, City, Country'
    },
    {
        id: 16,
        name: 'Life Vision Healthcare',
        address: '1234 Rajajinagar, City, Country'
    },
    {
        id: 17,
        name: 'Life Vision Healthcare',
        address: '5678 Vidyanagar, City, Country'
    },
    {
        id: 18,
        name: 'Life Vision Healthcare',
        address: '5678 Vidyanagar, City, Country'
    },
    {
        id: 19,
        name: 'Elvia care pvt ltd',
        address: '5678 Vidyanagar, City, Country'
    },
    {
        id: 20,
        name: 'Orchid Healthcare pvt ltd',
        address: '5678 Vidyanagar, City, Country'
    },
];
