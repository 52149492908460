
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, List, ListItem, ListItemText, Drawer, useMediaQuery, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const drawerList = (
    <List
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        padding: 0
      }}
    >
      <ListItem button component={Link} to="/" onClick={toggleDrawer(false)} style={{ width: '100%', textAlign: 'center' }}>
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem button component={Link} to="/about" onClick={toggleDrawer(false)} style={{ width: '100%', textAlign: 'center' }}>
        <ListItemText primary="About Us" />
      </ListItem>
      <ListItem button component={Link} to="/products" onClick={toggleDrawer(false)} style={{ width: '100%', textAlign: 'center' }}>
        <ListItemText primary="Products" />
      </ListItem>
      <ListItem button component={Link} to="/partners" onClick={toggleDrawer(false)} style={{ width: '100%', textAlign: 'center' }}>
        <ListItemText primary="Manufacturing Partners" />
      </ListItem>
      <ListItem button component={Link} to="/blogs" onClick={toggleDrawer(false)} style={{ width: '100%', textAlign: 'center' }}>
        <ListItemText primary="Blogs" />
      </ListItem>
      <ListItem button component={Link} to="/careers" onClick={toggleDrawer(false)} style={{ width: '100%', textAlign: 'center' }}>
        <ListItemText primary="Career" />
      </ListItem>
      <ListItem button component={Link} to="/contact" onClick={toggleDrawer(false)} style={{ width: '100%', textAlign: 'center' }}>
        <ListItemText primary="Contact" />
      </ListItem>
    </List>
  );

  return (
    <>
      <AppBar position="fixed" style={{ backgroundColor: '#ffffff', color: '#000000' }}>
        <Toolbar>
          <Link to="/" style={{ flexGrow: 1 }}>
            <img src="/images/bio_logo.jpg" alt="Biophilin Logo" style={{ height: '80px' }} />
          </Link>
          {isSmallScreen ? (
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          ) : (
            <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
              <Link to="/" style={{ textDecoration: 'none', color: 'inherit', margin: '0 15px' }}>Home</Link>
              <Link to="/about" style={{ textDecoration: 'none', color: 'inherit', margin: '0 15px' }}>About Us</Link>
              <Link to="/products" style={{ textDecoration: 'none', color: 'inherit', margin: '0 15px' }}>Products</Link>
              <Link to="/partners" style={{ textDecoration: 'none', color: 'inherit', margin: '0 15px' }}>Manufacturing Partners</Link>
              <Link to="/blogs" style={{ textDecoration: 'none', color: 'inherit', margin: '0 15px' }}>Blogs</Link>
              <Link to="/careers" style={{ textDecoration: 'none', color: 'inherit', margin: '0 15px' }}>Careers</Link>
              <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit', margin: '0 15px' }}>Contact</Link>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
      {isSmallScreen && (
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerList}
        </Drawer>
      )}
    </>
  );
};

export default Navbar;
